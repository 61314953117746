<template>
  <div>
    <query-form
      ref="queryRef"
      :query-form.sync="queryRecordForm"
      :form="form"
    >
      <template v-slot:buttons>
        <b-button
          variant="primary"
          class="mr-1"
          @click="search"
        >Search</b-button>
        <b-button
          variant="secondary"
          class="mr-1"
          @click="reset"
        >Reset</b-button>
        <b-button
          variant="primary"
          class="mr-1"
          @click="download"
        >Download</b-button>
      </template>
    </query-form>
    <table-list
      :table-data.sync="tableData"
      :list.sync="tableList"
      :page-num.sync="currentPage"
      :total-list.sync="totalList"
    />

  </div>
</template>
<script>
import {
  defineComponent, ref, getCurrentInstance, watch,
} from '@vue/composition-api'
import { BButton, BImg } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default defineComponent({
  setup() {
    const { proxy } = getCurrentInstance()
    // 表单
    const queryRecordForm = ref({})
    const form = ref([
      {
        label: 'Search Terms',
        itype: 'modeinput',
        imodel: 'searchTerms',
        placeholder: 'Enter Merchant Name...',
        Options: [{ title: 'Transaction ID', value: '7' }, { title: 'Merchant ID', value: '10' }, { title: 'Merchant Name', value: '11' }],
        labelKey: 'title',
        labelValue: 'value',
        defaultMode: { title: 'Transaction ID', value: '7' },
        md: 4,
      },
      {
        label: 'Transaction Date',
        itype: 'datetimeTerms',
        imodel: 'timeRangeDefault1',
        placeholder: 'Date',
        Options: [{ title: 'CREATE', value: 'create_time' }, { title: 'UPDATE', value: 'update_time' }],
        labelKey: 'title',
        labelValue: 'value',
        defaultMode: { title: 'CREATE', value: 'create_time' },
        md: 4,
      },
      {
        label: 'Status',
        itype: 'select',
        imodel: 'status',
        placeholder: '',
        Options: [
          { key: 'ALL', value: 0 },
          { key: 'Success', value: 1 },
          { key: 'Processing ', value: 2 },
          { key: 'Rejected', value: 3 },
          { key: 'Failed', value: 4 },
        ],
        labelKey: 'key',
        labelValue: 'value',
        defaultSelected: { key: 'ALL', value: 0 },
        // class: 'mt-1',
      },
      {
        label: 'Currency',
        itype: 'select',
        imodel: 'currency',
        placeholder: '',
        Options: [
          { key: 'ALL', value: 0 }, { key: 'BRL', value: 1 }, { key: 'MXN', value: 2 },
        ],
        labelKey: 'key',
        labelValue: 'value',
        defaultSelected: { key: 'ALL', value: 0 },
        class: 'mt-1',
      },
    ])
    // 列表
    const currentPage = ref(1)
    const tableList = ref([
      {
        transaction_id: 'SGDH23476436482DDFHS', merchant_name: 'Test123', merchant_id: 'SGDH23476436482DDFHS', status: 'Processing', amount_submitted: '45.2', amount_arrived: '45.2', currency: 'BRL', create_time: '1685603463', update_time: '1685603463',
      },
    ])
    const totalList = ref(0)
    const tableData = ref({
      tableColumns: [
        { key: 'transaction_id', label: 'TRANSACTION NUMBER' },
        { key: 'merchant_name', label: 'MERCHANT NAME' },
        { key: 'merchant_id', label: 'MERCHANT ID' },
        { key: 'status', label: 'STATUS' },
        { key: 'amount_submitted', label: 'AMOUNT SUBMITTED' },
        { key: 'amount_arrived', label: 'AMOUNT ARRIVED' },
        { key: 'currency', label: 'CURRENCY' },
        { key: 'create_time', label: 'CREATE TIME' },
        { key: 'update_time', label: 'UPDATE TIME' },
      ],
      templates: [
        { key: 'create_time', tType: 'datetime' },
        { key: 'update_time', tType: 'datetime' },
        { key: 'merchant_id', tType: 'merchant_merchant_id' },
        { key: 'transaction_id', tType: 'merchant_withdraw_transaction_id' },
      ],
    })
    // watch([timeZone], value => { console.log('activity时区', value[0]); timeRange.value = [] })
    const reset = () => {
      proxy.$refs.queryRef.reset()
    }
    const download = () => {}
    const getParams = () => {
      const newParams = {
        download: false, // bool
        page_no: currentPage.value, // number
        page_size: 10, // number
        filter: {
          merchant_search: {
            key: '', // string
            model: 0, // 1 TransactionID, 8 MerchantID, 13 MerchantSN, 14 MerchantName number
          },
          created_at: {
            begin: null, // 开始时间戳
            end: null, // 结束时间戳
          },
          updated_at: {
            begin: null, // 开始时间戳
            end: null, // 结束时间戳
          },
          transaction_status: 0, // 1 成功, 2 失败, 3 处理中 number
          currency: '', // string
          business_type: 13, // 12 Deposit, 13 Withdraw number
        },
      }
      // const newParams = {
      //   key: {
      //     model: 7,
      //     key: '',
      //   },
      //   time_scope: {
      //     start_date: null,
      //     end_date: null,
      //   },
      //   page_size: 10,
      //   page_no: currentPage.value,
      // }
      // if (queryRecordForm.value.searchTerms) {
      //   newParams.key.model = Number(queryRecordForm.value.searchTerms.modeValue)
      //   newParams.key.key = queryRecordForm.value.searchTerms.searchKey.trim()
      // }
      // if (queryRecordForm.value.timeRangeDefault1 && queryRecordForm.value.timeRangeDefault1.length > 0) {
      //   [newParams.time_scope.start_date, newParams.time_scope.end_date] = queryRecordForm.value.timeRangeDefault1
      // }
      return newParams
    }
    const fetchWithdrawList = async () => {
      const newParams = getParams()
      proxy.$showLoading()
      const res = await proxy.$api.fetchMerchantTransactionList(newParams)
      proxy.$hideLoading()
      const { code, data, message } = res.data
      if (code === 200) {
        const { pagination, list } = data
        tableList.value = list
        totalList.value = pagination.total_count
      } else {
        proxy.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: `${message}`,
            variant: 'danger',
          },
        }, {
          position: 'top-center',
        })
      }
    }
    const search = async () => {
      currentPage.value = 1
      await fetchWithdrawList()
    }
    watch([currentPage], async () => { await fetchWithdrawList() })
    return {
      queryRecordForm,
      tableData,
      tableList,
      currentPage,
      totalList,

      // func
      search,
      reset,
      download,
      form,
    }
  },
  components: {
    BButton, BImg,
    // BCard, BForm, BRow, BCol, BInputGroup, BFormInput, BButton, BTable, BPagination, BInputGroupPrepend, BDropdown, BDropdownItem, DatePicker,
  },
})
</script>
